<template>
    <o-data-lookup :data-object="dsResources" contextField="OrgUnitIdPath">
        <template #target="{ target }">
            <component v-if="is" :is="is" :ref="target" v-bind="$attrs" :value="textInputValue"></component>
            <input v-else-if="textInput" :ref="target" v-bind="$attrs" :value="textInputValue">
            <span v-else :ref="target" style="cursor:pointer;">
                
            </span>
        </template>
        <o-column field="ID" width="80"></o-column>
        <o-column field="OrgUnit" width="300"></o-column>
        <o-column field="FunctionRole" width="175"></o-column>
        <o-column field="Responsible" width="200"></o-column>
        <o-column field="FromDate" width="100"></o-column>
        <o-column field="ToDate" width="100"></o-column>
    </o-data-lookup>
</template>


<script setup>    
import { getOrCreateDataObject } from 'o365.vue.ts'

const props = defineProps({
    textInput: Boolean,
    is: String,
    textInputValue: String,        
});

const dsResources = getOrCreateDataObject({
    id: 'dsResources' + crypto.randomUUID(),
    viewName: 'aviw_Resource_Positions',
    maxRecords: 25,
    whereClause: "",
    loadRecents: false,
    distinctRows: true,
    fields: [
        {name: "ID", type: "number" },
        {name: "OrgUnit", type: "string" },
        {name: "FunctionRole", type: "string" },
        {name: "FromDate", type: "date" },
        {name: "ToDate", type: "date" },            
        {name: "Responsible", type: "string" },
    ],
});    
</script>
